import * as React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
// import HeaderLogo from "./elements/headerLogo"
import HeaderMenuItem from "./elements/headerMenuItem"
import HeaderExternalMenuItem from "./elements/headerExternalMenuItem"
import ScrollController from "./elements/scrollController"
import { useStaticQuery, graphql } from "gatsby"
// import lottie from "lottie-web"
import Lottie from "lottie-react-web"
import logoLight from "../images/light-logo.json"
import logoDark from "../images/dark-logo.json"
import ToggleSwitch from "./elements/toggleSwitch"
import HooxBrandmark from "../images/hoox-brandmark.inline.svg"
import SitenuBrandmark from "../images/sitenu-brandmark.inline.svg"
import SendtodeliverBrandmark from "../images/sendtodeliver-brandmark.inline.svg"
import WildeBrandmark from "../images/wilde-brandmark.inline.svg"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faTimes } from "@fortawesome/pro-regular-svg-icons"

const Header = props => {
  const data = useStaticQuery(graphql`
    {
      allJobsLoyall {
        edges {
          node {
            _0
          }
        }
      }
    }
  `)

  const [mobile, setMobile] = React.useState(false)
  const [vacancies, setOpenVacancies] = React.useState(null)

  // React.useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     document.querySelector("#loyall-logo").innerHTML = ""

  //     lottie.loadAnimation({
  //       container: document.querySelector("#loyall-logo")
  //         ? document.querySelector("#loyall-logo")
  //         : null,
  //       animationData: props.theme === "light" ? logoDark : logoLight,
  //       renderer: "svg", // "canvas", "html"
  //       loop: true, // boolean
  //       autoplay: true, // boolean
  //     })
  //   }
  // }, [props.theme])

  React.useEffect(() => {
    setOpenVacancies(data.allJobsLoyall.edges[0].node._0)
    console.log(data.allJobsLoyall.edges[0].node._0)
  }, [])

  return (
    <header>
      <ScrollController />
      <Container>
        <Row>
          <Col className="d-flex align-items-center header-wrapper">
            <div className="header-logo-wrapper">
              {/* <HeaderLogo /> */}
              <Link to="/">
                <Lottie
                  options={{
                    animationData:
                      props.theme === "light" ? logoDark : logoLight,
                    renderer: "svg",
                    loop: true,
                  }}
                />
              </Link>
            </div>
            <div className="header-menu-controller">
              <button
                id="open-mobile-menu"
                onClick={() => {
                  if (typeof window !== "undefined") {
                    document.querySelector("body").classList.add("block-scroll")
                    window.scrollTo(0, 0)
                  }
                  setMobile(!mobile)
                }}
                className={`${mobile ? "d-none" : ""}`}
              >
                {" "}
                <FontAwesomeIcon icon={faBars} />
              </button>
              <button
                id="close-mobile-menu"
                onClick={() => {
                  setMobile(!mobile)
                  if (typeof window !== "undefined") {
                    window.scrollTo(0, 0)
                    document
                      .querySelector("body")
                      .classList.remove("block-scroll")
                  }
                }}
                className={`${mobile ? "" : "d-none"}`}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <div
              className={`header-menu-mobile-wrapper ${
                mobile ? "mobile-menu-opened" : ""
              }`}
            >
              <div className="mobile-menu-links-wrapper">
                <HeaderMenuItem
                  link="/the-rebranding-story"
                  text="The rebranding story"
                  classes="bordered-bottom"
                  clickevent={true}
                />
                <HeaderMenuItem
                  link="#"
                  text="De labels"
                  dropdown={true}
                  classes="bordered-bottom"
                  dropdownContent={
                    <div className="container-fluid pr-0 pl-0">
                      <div className="row width-100">
                        <div className="col-12 d-flex align-items-center">
                          <Link
                            to="/design"
                            onClick={() => {
                              if (typeof window !== "undefined") {
                                document
                                  .getElementById("close-mobile-menu")
                                  .click()
                              }
                            }}
                          >
                            <WildeBrandmark />
                            <h6 className="m-0">Design</h6>
                          </Link>
                        </div>
                        <div className="col-12 d-flex align-items-center">
                          <Link
                            to="/marketing"
                            onClick={() => {
                              if (typeof window !== "undefined") {
                                document
                                  .getElementById("close-mobile-menu")
                                  .click()
                              }
                            }}
                          >
                            <SendtodeliverBrandmark />
                            <h6 className="m-0">Marketing</h6>
                          </Link>
                        </div>
                        <div className="col-12 d-flex align-items-center">
                          <Link
                            to="/development"
                            onClick={() => {
                              if (typeof window !== "undefined") {
                                document
                                  .getElementById("close-mobile-menu")
                                  .click()
                              }
                            }}
                          >
                            <SitenuBrandmark />
                            <h6 className="m-0">Development</h6>
                          </Link>
                        </div>
                        <div className="col-12 d-flex align-items-center">
                          <Link
                            to="/integrations"
                            onClick={() => {
                              if (typeof window !== "undefined") {
                                document
                                  .getElementById("close-mobile-menu")
                                  .click()
                              }
                            }}
                          >
                            <HooxBrandmark />
                            <h6 className="m-0">Integrations</h6>
                          </Link>
                        </div>
                      </div>
                    </div>
                  }
                />
                <HeaderMenuItem
                  link="/onze-aanpak"
                  text="Onze aanpak"
                  classes="bordered-bottom"
                  clickevent={true}
                />
                {/* <HeaderMenuItem
                  link="/ons-werk"
                  text="Ons werk"
                  classes="bordered-bottom"
                /> */}
                <HeaderMenuItem
                  link="/resources"
                  text="Resources"
                  classes="bordered-bottom"
                  clickevent={true}
                />
                <HeaderExternalMenuItem
                  link="https://jobs.loyall.co/"
                  text="Careers"
                  classes="bordered-bottom"
                  clickevent={true}
                />
                <HeaderMenuItem
                  link="/discovery"
                  text="Discovery"
                  button={true}
                  clickevent={true}
                />
              </div>
              <div className="mobile-menu-contact-theme-wrapper">
                <div className="mobile-menu-contact-info mt-3">
                  <h6 className="smaller">Get in touch</h6>
                  <a href="mailto:info@loyall.co">info@loyall.co</a>
                </div>
                <div className="mobile-menu-theme-toggle mt-3">
                  <h6 className="smaller">UI Theme</h6>
                  <ToggleSwitch
                    theme={props.theme}
                    themeToggler={props.themeToggler}
                  />
                </div>
              </div>
            </div>
            <div className="align-self-center header-menu-wrapper align-items-center">
              <div className="align-items-center d-flex">
                <Col
                  xs={12}
                  md={5}
                  lg={7}
                  xl={7}
                  className="text-xl-center d-flex justify-content-xl-between justify-content-end text-right"
                >
                  <HeaderMenuItem
                    link="/the-rebranding-story"
                    text="The rebranding story"
                    classes="header-menu-item-rebranding text-left d-none d-xl-block"
                  />
                  <div>
                    <HeaderMenuItem
                      link="#"
                      text="De labels"
                      dropdown={true}
                      dropdownContent={
                        <div className="container-fluid header-menu-desktop-labels p-0 text-left">
                          <div className="row">
                            <div className="col-3">
                              <Link to="/design">
                                <WildeBrandmark />
                                <h6>Design</h6>
                                <p>
                                  Breng je merkidentiteit tot leven met
                                  vooruitstrevende branding, webdesigns en
                                  animaties.
                                </p>
                              </Link>
                            </div>
                            <div className="col-3">
                              <Link to="/marketing">
                                <SendtodeliverBrandmark />
                                <h6>Marketing</h6>
                                <p>
                                  Vertel je verhaal aan de juiste mensen met
                                  content, campagnes en recruitment marketing.
                                </p>
                              </Link>
                            </div>
                            <div className="col-3">
                              <Link to="/development">
                                <SitenuBrandmark />
                                <h6>Development</h6>
                                <p>
                                  Versterk de fundering van je organisatie met
                                  custom webapplicaties, hosting en
                                  SaaS-oplossingen.
                                </p>
                              </Link>
                            </div>
                            <div className="col-3">
                              <Link to="/integrations">
                                <HooxBrandmark />
                                <h6>Integrations</h6>
                                <p>
                                  Laat je systemen soepel samenwerken met de
                                  juiste integraties en benut alle data die je
                                  verzamelt.
                                </p>
                              </Link>
                            </div>
                          </div>
                        </div>
                      }
                    />
                    <HeaderMenuItem link="/onze-aanpak" text="Onze aanpak" />
                    {/* <HeaderMenuItem link="/ons-werk" text="Ons Werk" /> */}
                    <HeaderMenuItem link="/resources" text="Resources" />
                    <HeaderExternalMenuItem
                      link="https://jobs.loyall.co/"
                      text="Careers"
                      number={8}
                    />
                  </div>
                </Col>
                <Col xs={12} md={5} lg={5} className="text-right">
                  <ToggleSwitch
                    theme={props.theme}
                    themeToggler={props.themeToggler}
                  />
                  <HeaderMenuItem
                    link="/discovery"
                    text="Discovery"
                    button={true}
                  />

                  {/* <button onClick={props.themeToggler}>Switch Theme</button> */}
                </Col>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  )
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
