import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <footer>
      <Container className="footer-main">
        <Row>
          <Col xs={12} lg={4}>
            <p className="font-weight-bold font-2">Loyall B.V.</p>
            <p>
              We helpen bedrijven bouwen aan een betere en sterkere digital
              lifecycle om op een duurzame manier te kunnen groeien.
            </p>
            <p className="mb-0">
              <a href="mailto:hello@loyall.co">hello@loyall.co</a>
            </p>
            <p className="mb-0">
              <a href="tel:020 210 1234">020 210 1234</a>
            </p>
            <p className="mb-0">KVK: 82247978</p>
            <p className="mb-0">VAT: 862393620B01</p>
          </Col>

          <Col xs={12} lg={{ span: 2, offset: 2 }}>
            <p className="font-weight-bold font-2">Menu</p>
            <p>
              <Link to="/">Home</Link>
            </p>
            <p>
              <Link to="/the-rebranding-story">The rebranding story</Link>
            </p>
            <p>
              <Link to="/onze-aanpak">Onze aanpak</Link>
            </p>
            {/* <p><Link to="/ons-werk">Ons werk</Link></p> */}
            <p>
              <Link to="/resources">Resources</Link>
            </p>
          </Col>
          <Col xs={12} lg={2}>
            <p className="font-weight-bold font-2">Labels</p>
            <p>
              <Link to="/design">Wilde Amsterdam</Link>
            </p>
            <p>
              <Link to="/marketing">SendtoDeliver</Link>
            </p>
            <p>
              <Link to="/development">Site.nu</Link>
            </p>
            <p>
              <Link to="/integrations">Hoox</Link>
            </p>
          </Col>
          <Col xs={12} lg={2}>
            <p className="font-weight-bold font-2">Company</p>
            <p>
              <a
                href="https://jobs.loyall.co/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Careers
              </a>
            </p>
            <p>
              <a
                href="https://indd.adobe.com/view/85ff3263-348e-4273-999a-6a136d7a0595"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy policy
              </a>
            </p>
            <p>
              <a
                href="https://indd.adobe.com/view/e720b532-5a1f-4357-8963-7f1eee41061f"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms & conditions
              </a>
            </p>
          </Col>
        </Row>
      </Container>
      <Container className="footer-copyrights">
        <Row>
          <Col>
            <p>
              &copy; Loyall B.V. {new Date().getFullYear()}. All rights
              reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
