/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
// import {Link} from "gatsby";
import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "styled-components"
import { GlobalStyles } from "./globalStyles"
import { lightTheme, darkTheme } from "./Themes"
import Header from "./header"
import Footer from "./footer"
import CookieConsent from "react-cookie-consent"
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./layout.css"
import "../scss/app.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const [theme, setTheme] = React.useState(
    typeof window !== "undefined" && localStorage.theme !== undefined
      ? localStorage.theme
      : "light"
  )

  const themeToggler = () => {
    // console.log('click');
    theme === "light" ? setTheme("dark") : setTheme("light")
    if (typeof window !== "undefined") {
      if (localStorage.theme !== undefined) {
        localStorage.theme = localStorage.theme === "light" ? "dark" : "light"
      } else {
        localStorage.theme = "dark"
      }
    }
  }

  if (typeof window !== "undefined") {
    document.querySelectorAll('a[href^="#"]').forEach(trigger => {
      trigger.onclick = function (e) {
        e.preventDefault()
        let hash = this.getAttribute("href")
        let target = document.querySelector(hash)
        let headerOffset = 90
        let elementPosition = target.offsetTop
        let offsetPosition = elementPosition - headerOffset

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        })
      }
    })
  }
  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <>
        <GlobalStyles />
        <>
          <Header
            siteTitle={data.site.siteMetadata?.title || `Title`}
            theme={theme}
            themeToggler={themeToggler}
          />
          <main>{children}</main>
          <Footer />
        </>
      </>
      <CookieConsent
        location="bottom"
        buttonText="Alles accepteren"
        enableDeclineButton
        declineButtonText="Negeren"
        flipButtons="true"
        hideOnAccept="false"
        cookieName="gatsby-gdpr-google-tagmanager"
        style={{
          background: `${theme === "light" ? "#17181A" : "#FFF"}`,
          color: `${theme === "light" ? "#FFF" : "#17181A"}`,
          fontSize: "14px",
        }}
        buttonStyle={{
          background: `${theme === "light" ? "#FFF" : "#17181A"}`,
          color: `${theme === "light" ? "#17181A" : "#FFF"}`,
          borderRadius: "50px",
          fontSize: "14px",
          padding: "11px 40px 11px 25px",
        }}
        expires={150}
      >
        Wij gebruiken cookies om onze website en onze service te optimaliseren.{" "}
        <span>
          <a
            style={{ fontSize: "14px", color: "#2669FF" }}
            href="https://indd.adobe.com/view/85ff3263-348e-4273-999a-6a136d7a0595"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </span>
      </CookieConsent>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
