import * as React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faHorizontalRule, faPlus, faMinus} from '@fortawesome/pro-regular-svg-icons'

const HeaderMenuItem = ({link, text, dropdown=false, dropdownContent=null, button=false, classes, clickevent=false}) => {
    if(dropdown){
        return(
            <div className="header-menu-item-dropdown">
                <Link to={link} className={`header-menu-item ${button ? "btn btn-solid btn-menu-item":""} ${classes!==undefined?classes:""}`}>
                    {text} <FontAwesomeIcon icon={faPlus} /> <FontAwesomeIcon icon={faMinus} />
                </Link>
                <div className="header-menu-item-dropdown--content-wrapper">
                    <div className="header-menu-item-dropdown--content">
                        {dropdownContent}
                    </div>
                </div>
            </div>
        )
    }else{
        return(
                <Link to={link} className={`header-menu-item ${button ? "btn btn-solid btn-menu-item":""} ${classes!==undefined?classes:""}`} onClick={()=>{
                if (clickevent && typeof window !== 'undefined') {
                    document.getElementById('close-mobile-menu').click();
                }
            }}>
                    {text} {button ? <FontAwesomeIcon icon={faHorizontalRule} />:""}
                </Link>
            )
        
    }
    }
HeaderMenuItem.propTypes = {
    link: PropTypes.string,
    text: PropTypes.string
  }
  
  HeaderMenuItem.defaultProps = {
    link: ``,
    text: ``
  }
export default HeaderMenuItem
