export const lightTheme = {
  body: "#FFF",
  header: "#FFF",
  text: "#17181A",
  toggleBorder: "#17181A33",
  background: "#363537",
  switch_background: "#17181A",
  switch_head: "#FFF",
  mobile_menu_border: "#DEDEDE",
  scroll_arrow_bg: "#FFFFFF",
  scroll_arrow_color: "#3C3E44",
  scroll_arrow_border: "#D5D5D5",
  light_button_background: "#FFF",
  light_button_text: "#17181A",
  dark_button_background: "#17181A",
  dark_button_text: "#FFF",
  search_background: "#FFF",
  search_text_color: "#4E5258",
}
export const darkTheme = {
  body: "#17181A",
  header: "#17181A",
  text: "#FFFFFF",
  toggleBorder: "#3D3E44",
  background: "#17181A",
  switch_background: "#FFF",
  switch_head: "#17181A",
  mobile_menu_border: "#3D3E44",
  scroll_arrow_bg: "#17181A",
  scroll_arrow_color: "#FFFFFF",
  scroll_arrow_border: "#3D3E44",
  light_button_background: "#17181A",
  light_button_text: "#FFF",
  dark_button_background: "#FFF",
  dark_button_text: "#17181A",
  search_background: "#17181A",
  search_text_color: "#FFF",
}
