import * as React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faHorizontalRule,
  faPlus,
  faMinus,
} from "@fortawesome/pro-regular-svg-icons"

const HeaderExternalMenuItem = ({
  link,
  text,
  dropdown = false,
  dropdownContent = null,
  button = false,
  classes,
  clickevent = false,
  number,
}) => {
  if (dropdown) {
    return (
      <div className="header-menu-item-dropdown">
        <a
          href={link}
          className={`header-menu-item ${
            button ? "btn btn-solid btn-menu-item" : ""
          } ${classes !== undefined ? classes : ""}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {text} <FontAwesomeIcon icon={faPlus} />{" "}
          <FontAwesomeIcon icon={faMinus} />
        </a>
        <div className="header-menu-item-dropdown--content-wrapper">
          <div className="header-menu-item-dropdown--content">
            {dropdownContent}
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <>
        <a
          href={link}
          className={`header-menu-item ${
            button ? "btn btn-solid btn-menu-item" : ""
          } ${classes !== undefined ? classes : ""}`}
          onClick={() => {
            if (clickevent && typeof window !== "undefined") {
              document.getElementById("close-mobile-menu").click()
            }
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          {text} {button ? <FontAwesomeIcon icon={faHorizontalRule} /> : ""}
          {number && (
            <div className="header-menu-item-data">
              <div>{number}</div>
            </div>
          )}
        </a>
      </>
    )
  }
}
HeaderExternalMenuItem.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string,
}

HeaderExternalMenuItem.defaultProps = {
  link: ``,
  text: ``,
}
export default HeaderExternalMenuItem
