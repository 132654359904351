import * as React from "react"

const ToggleSwitch = (props) => {
    return (
    <label className="switch vertical-align-middle">
        <input type="checkbox"  onChange={props.themeToggler} checked={props.theme==="light"? 0:1}/>
        <div></div>
    </label>
    // <label className="switch" onClick={props.themeToggler}>
    //     <input type="checkbox" checked={props.theme==="light"? 1:0}/>
    //     <span className="slider round"></span>
    // </label>      
    )
}
export default ToggleSwitch
