import { createGlobalStyle } from "styled-components"
export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
  }
  .accordion-wrapper button{
    color: ${({ theme }) => theme.text};
  }
  header {
    background: ${({ theme }) => theme.header};
  }
  a {
    color: ${({ theme }) => theme.text};
  }
  .switch{
    background: ${({ theme }) => theme.switch_background};
  }
  .switch div {
    background: ${({ theme }) => theme.switch_head};
  }
  .header-menu-item-dropdown--content{
    background: ${({ theme }) => theme.body};
    border: 1px solid ${({ theme }) => theme.toggleBorder};
  }
  .btn-menu-item, .hs-submit input[type="submit"]{
    background: ${({ theme }) => theme.dark_button_background};
    color: ${({ theme }) => theme.dark_button_text};
  }
  .btn-outline {
    border:1px solid ${({ theme }) => theme.text};
    color: ${({ theme }) => theme.text};
  }
  .btn-outline:hover {
    color: ${({ theme }) => theme.body};
    background: ${({ theme }) => theme.text};
  }
  .btn-link {
    color: ${({ theme }) => theme.text};
  }
  .mobile-menu-opened {
    background: ${({ theme }) => theme.header};
  }
  .mobile-menu-opened .bordered-bottom{
    border-bottom: 1px solid ${({ theme }) => theme.mobile_menu_border}
  }
  .scroll-down-arrow{
    background: ${({ theme }) => theme.scroll_arrow_bg};
    color: ${({ theme }) => theme.scroll_arrow_color};
    border: 1px solid ${({ theme }) => theme.scroll_arrow_border};
  }
  .scroll-down-arrow:hover{
    background: ${({ theme }) => theme.dark_button_background};
    color: ${({ theme }) => theme.dark_button_text};
    border: 1px solid ${({ theme }) => theme.light_button_text};
  }
  .btn-loyall{
    background: ${({ theme }) => theme.light_button_background};
    color: ${({ theme }) => theme.light_button_text};
  }
  .btn-loyall:hover{
    background: ${({ theme }) => theme.dark_button_background};
    color: ${({ theme }) => theme.dark_button_text};
  }
  .btn-loyall.btn-dark{
    background: ${({ theme }) => theme.dark_button_background};
    color: ${({ theme }) => theme.dark_button_text};
  }
  .btn-loyall.btn-dark .fa-horizontal-rule path {
    fill: ${({ theme }) => theme.dark_button_text};
  }
  .btn-loyall .fa-horizontal-rule path {
    fill: ${({ theme }) => theme.light_button_text};
  }
  .btn-loyall.btn-dark:hover .fa-horizontal-rule path {
    fill: ${({ theme }) => theme.light_button_text};
  }
  .btn-loyall:hover .fa-horizontal-rule path {
    fill: ${({ theme }) => theme.dark_button_text};
  }
  .CookieConsent button::after, #rcc-decline-button{
    background: ${({ theme }) => theme.dark_button_background};
  }
  #open-mobile-menu, #close-mobile-menu{
    color: ${({ theme }) => theme.text};
  }
  .search--input {
    background-color: ${({ theme }) => theme.search_background};
    color: ${({ theme }) => theme.search_text_color};
  }
  `
