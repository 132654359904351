import * as React from "react"

const ScrollController = (props) => {
    const [scrollTop, setScrollTop] = React.useState(0)
    
    React.useEffect(() => {
      const onScroll = e => {
        setScrollTop(e.target.documentElement.scrollTop);
      };
      if (typeof window !== 'undefined') {
        window.addEventListener("scroll", onScroll);
        if(scrollTop >= 1){
          document.querySelector('header').classList.add('header-fixed');
        }else{
          document.querySelector('header').classList.remove('header-fixed');
        }
        return () => window.removeEventListener("scroll", onScroll);
      }
    }, [scrollTop])
    return null;
}
export default ScrollController
